// Components
import '@styles/components/c-breadcrumbs.scss';
import '@styles/components/c-btn.scss';
import '@styles/components/c-footer-accreditations.scss';
import '@styles/components/c-footer-contact.scss';
// import '@styles/components/c-footer-credit.scss';
import '@styles/components/c-footer-legal.scss';
import '@styles/components/c-footer-nav.scss';
import '@styles/components/c-form.scss';
import '@styles/components/c-nav-banner.scss';
import '@styles/components/c-newsletter.scss';
import '@styles/components/c-newsletter-widget.scss';
import '@styles/components/c-skip-link.scss';
import '@styles/components/c-styled-select.scss';
import '@styles/components/c-logo-stag.scss';
import '@styles/components/c-mobile-menu-panel.scss';
import '@styles/components/c-livechat.scss';

// Modules
import livechat from '@modules/livechat';

// Bootstrap
livechat();
